/*  Reset CSS File Sass */
@charset "UTF-8";
html {
  box-sizing: border-box; }

* {
  box-sizing: inherit;

  &:before, &:after {
    box-sizing: inherit; } }

body {
  -webkit-font-smoothing: antialiased;
  color: #222;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
  text-rendering: optimizeLegibility; }

h1, h2, h3, h4, h5, h6, dl, dd, ol, ul, menu, figure, blockquote, p, pre, form {
  margin: 0; }

menu, ol, ul {
  list-style-image: none;
  list-style: none;
  padding: 0; }

main {
  display: block; }

a {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  color: inherit;
  text-decoration: underline;
  text-underline-position: under; }

audio, canvas, iframe, img, svg, video {
  vertical-align: middle; }

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

svg {
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0; }
