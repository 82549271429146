/*  Scss Modular Fluid Text Document */
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number)=="number"and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

html {
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  overflow-wrap: normal;
  padding: 0 1px;
}

// Fluid Modular Scale this!
h1,
h2,
h3,
h4 {
  letter-spacing: 0.0313em;
  margin: 0.75em 0;
  font-family: kalam;
  font-style: normal;
  font-weight: 700;
}

h2 {
  color: #006639;
  text-decoration: underline;
}

h1:focus,
h2:focus,
h3:focus,
h4:focus {
  outline: 2px solid red;
}

p,
figcaption {
  letter-spacing: 0.0313em;
  margin: 0.75em 0;
}

h1 {
  @include fluid-type(font-size, 30em, 80em, 1.751rem, 3.125rem);
}

h2 {
  @include fluid-type(font-size, 30em, 80em, 1.5rem, 2.5rem);
}

h3 {
  @include fluid-type(font-size, 30em, 80em, 1.25rem, 2rem);
}

h4 {
  @include fluid-type(font-size, 30em, 80em, 1.175rem, 1.75rem);
}

p,
li,
figcaption,
address,
a {
  @include fluid-type(font-size, 30em, 80em, 1.1rem, 1.26rem);
}

.small {
  font-size: small;
}

a {
  color: #1300e1;
  letter-spacing: 0.0313em;
  padding: 4px;
  text-decoration: underline;
  text-underline-position: under;
  white-space: normal;

  &:link {
    color: #1300e1;
  }

  &:visited {
    color: #0045A0;
  }

  &:active {
    background-color: #8FBC8F;
  }

  &:hover {
    background-color: #b2ffff;
    color: #001090;
    cursor: pointer;
    text-decoration-color: red;
  }

  &:focus {
    background-color: #b2ffff;
    color: #001090;
    outline-style: auto;
    outline: 2px solid red;
    outline-offset: -1px;
    text-decoration-color: red;
  }

  &:before,
  &:after {
    text-decoration: none;
  }
}

a[target="_blank"]:not([href*="kundenmaler-willi.ch"]):not([href*="pdf/"]) {
  background-image: url(../img/extern.svg);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 14px;
  padding: 2px 16px 0 0;
  text-decoration: none;
}

a[target="_blank"]:not([href*="kundenmaler-willi.ch"]):hover {
  background-color: #ffdcdc;
}

[href*="mailto:"] {
  background-image: url(../img/mailbl.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 22px;
  padding-right: 25px;
}

a[href*="tel:"] {
  background-image: url(../img/phone.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 25px;
}

li {
  line-height: 2;
}

// ICONS SVG
.icon {
  fill: currentColor;
  height: 1em;
  margin-right: 3px;
  position: relative;
  stroke-width: 0;
  stroke: currentColor;
  width: 1em;
}

.icon-checkmark2 {
  fill: #006639;
}

.red {
  color: red;
  vertical-align: baseline;
  font-size: 1.3em;
}

.rot {
  color: red;
}

/* kalam-700 - latin */
@font-face {
  font-family: "Kalam";
  font-style: normal;
  font-weight: 700;
  src: local("Kalam"), url("../font/kalam-v11-latin-700.woff2") format("woff2"),
    url("../font/kalam-v11-latin-700.woff") format("woff");
  font-display: swap;
}

/*    End Fluid scss    */