// Helfer SASS

hr {
  border: 0;
  border-top: 1px solid #00e5ff;
  display: block;
  margin: 2em auto;
  padding: 0;
  width: 90%; }

img {
  height: auto;
  max-width: 100%; }

.hidden {
  display: none; }

.vh {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
  &.focusable {
    &:active, &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      white-space: inherit;
      width: auto; } } }

.nobreak {
  hyphens: none;
  white-space: nowrap; }

.center {
  text-align: center; }

.address h3 {
  margin: 0; }

.address {
  background-image: url(../img/farbklecks-01.svg);
  background-repeat: no-repeat;
  padding: 2.6em 1.2em 3.2em 4.6em; }

.map {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.3);
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

.no-js iframe {
  display: none; }
.no-js .map {
  background-image: url(../img/bg-map.png);
  background-size: cover; }

.service {
  font-weight: 700;
  padding-left: 40px;

  li {
    margin: 1.8em 0;
    list-style: none;
    text-indent: -1.4em; } }

@media only screen and (min-width: 42.5em) {
  .service {
    background-image: url(../img/kleggse3.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    margin-right: 2.60vw; } }

@media only screen and (min-width: 31.25em) {
  .no-js .tele {
    background-size: 150px; }

  .tele {
    background-image: url(../img/farbeimer-gelb.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    margin-right: 4vw; } }

@media only screen and (min-width: 48.063em) {
  .address {
    padding-left: 5em; } }

.no-js div.tele {
  background-size: 150px; }
