/*  Reset CSS File Sass */
html {
  box-sizing: border-box; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

body {
  -webkit-font-smoothing: antialiased;
  color: #222;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  position: relative;
  text-rendering: optimizeLegibility; }

h1, h2, h3, h4, h5, h6, dl, dd, ol, ul, menu, figure, blockquote, p, pre, form {
  margin: 0; }

menu, ol, ul {
  list-style-image: none;
  list-style: none;
  padding: 0; }

main {
  display: block; }

a {
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  color: inherit;
  text-decoration: underline;
  text-underline-position: under; }

audio, canvas, iframe, img, svg, video {
  vertical-align: middle; }

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

svg {
  height: 0;
  overflow: hidden;
  position: absolute;
  width: 0; }

/*  Scss Modular Fluid Text Document */
html {
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 100%;
  line-height: 1.5;
  scroll-behavior: smooth; }

body {
  font-size: 1rem;
  overflow-wrap: normal;
  padding: 0 1px; }

h1,
h2,
h3,
h4 {
  letter-spacing: 0.0313em;
  margin: 0.75em 0;
  font-family: kalam;
  font-style: normal;
  font-weight: 700; }

h2 {
  color: #006639;
  text-decoration: underline; }

h1:focus,
h2:focus,
h3:focus,
h4:focus {
  outline: 2px solid red; }

p,
figcaption {
  letter-spacing: 0.0313em;
  margin: 0.75em 0; }

h1 {
  font-size: 1.751rem; }
  @media (min-width: 30em) {
    h1 {
      font-size: calc(1.751rem + 1.374 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h1 {
      font-size: 3.125rem; } }

h2 {
  font-size: 1.5rem; }
  @media (min-width: 30em) {
    h2 {
      font-size: calc(1.5rem + 1 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h2 {
      font-size: 2.5rem; } }

h3 {
  font-size: 1.25rem; }
  @media (min-width: 30em) {
    h3 {
      font-size: calc(1.25rem + 0.75 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h3 {
      font-size: 2rem; } }

h4 {
  font-size: 1.175rem; }
  @media (min-width: 30em) {
    h4 {
      font-size: calc(1.175rem + 0.575 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    h4 {
      font-size: 1.75rem; } }

p,
li,
figcaption,
address,
a {
  font-size: 1.1rem; }
  @media (min-width: 30em) {
    p,
    li,
    figcaption,
    address,
    a {
      font-size: calc(1.1rem + 0.16 * (100vw - 30em) / 50); } }
  @media (min-width: 80em) {
    p,
    li,
    figcaption,
    address,
    a {
      font-size: 1.26rem; } }

.small {
  font-size: small; }

a {
  color: #1300e1;
  letter-spacing: 0.0313em;
  padding: 4px;
  text-decoration: underline;
  text-underline-position: under;
  white-space: normal; }
  a:link {
    color: #1300e1; }
  a:visited {
    color: #0045A0; }
  a:active {
    background-color: #8FBC8F; }
  a:hover {
    background-color: #b2ffff;
    color: #001090;
    cursor: pointer;
    text-decoration-color: red; }
  a:focus {
    background-color: #b2ffff;
    color: #001090;
    outline-style: auto;
    outline: 2px solid red;
    outline-offset: -1px;
    text-decoration-color: red; }
  a:before, a:after {
    text-decoration: none; }

a[target="_blank"]:not([href*="kundenmaler-willi.ch"]):not([href*="pdf/"]) {
  background-image: url(../img/extern.svg);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 14px;
  padding: 2px 16px 0 0;
  text-decoration: none; }

a[target="_blank"]:not([href*="kundenmaler-willi.ch"]):hover {
  background-color: #ffdcdc; }

[href*="mailto:"] {
  background-image: url(../img/mailbl.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 22px;
  padding-right: 25px; }

a[href*="tel:"] {
  background-image: url(../img/phone.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 25px; }

li {
  line-height: 2; }

.icon {
  fill: currentColor;
  height: 1em;
  margin-right: 3px;
  position: relative;
  stroke-width: 0;
  stroke: currentColor;
  width: 1em; }

.icon-checkmark2 {
  fill: #006639; }

.red {
  color: red;
  vertical-align: baseline;
  font-size: 1.3em; }

.rot {
  color: red; }

/* kalam-700 - latin */
@font-face {
  font-family: "Kalam";
  font-style: normal;
  font-weight: 700;
  src: local("Kalam"), url("../font/kalam-v11-latin-700.woff2") format("woff2"), url("../font/kalam-v11-latin-700.woff") format("woff");
  font-display: swap; }

/*    End Fluid scss    */
hr {
  border: 0;
  border-top: 1px solid #00e5ff;
  display: block;
  margin: 2em auto;
  padding: 0;
  width: 90%; }

img {
  height: auto;
  max-width: 100%; }

.hidden {
  display: none; }

.vh {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important; }
  .vh.focusable:active, .vh.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto; }

.nobreak {
  hyphens: none;
  white-space: nowrap; }

.center {
  text-align: center; }

.address h3 {
  margin: 0; }

.address {
  background-image: url(../img/farbklecks-01.svg);
  background-repeat: no-repeat;
  padding: 2.6em 1.2em 3.2em 4.6em; }

.map {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.3);
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative; }
  .map iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.no-js iframe {
  display: none; }

.no-js .map {
  background-image: url(../img/bg-map.png);
  background-size: cover; }

.service {
  font-weight: 700;
  padding-left: 40px; }
  .service li {
    margin: 1.8em 0;
    list-style: none;
    text-indent: -1.4em; }

@media only screen and (min-width: 42.5em) {
  .service {
    background-image: url(../img/kleggse3.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    margin-right: 2.60vw; } }

@media only screen and (min-width: 31.25em) {
  .no-js .tele {
    background-size: 150px; }
  .tele {
    background-image: url(../img/farbeimer-gelb.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    margin-right: 4vw; } }

@media only screen and (min-width: 48.063em) {
  .address {
    padding-left: 5em; } }

.no-js div.tele {
  background-size: 150px; }

#top {
  background-color: #0a2d6d; }

.header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto; }
  .header img {
    width: 13em; }
  .header h1 {
    color: red;
    font-weight: 700;
    text-decoration: none; }

@media only screen and (max-width: 23.75em) {
  .header img {
    height: 140px;
    margin-left: -14px; }
  .header h1 {
    margin-top: -0.75em; } }

@media only screen and (min-width: 48.063em) {
  .header img {
    width: 18em;
    margin-right: 10vw; }
  .header h1 {
    font-size: 4.2em; } }

@media only screen and (min-width: 57.5em) {
  .header img {
    width: 20em; }
  .header h1 {
    font-size: 4.5em; } }

.no-js nav a#nav-m, .no-js nav a.close {
  background: #f6eb00;
  border-radius: .3em;
  border: 2px solid #00AA4F;
  color: #000;
  display: block;
  font-size: 1.5rem;
  padding: .1em .25em;
  position: absolute;
  right: 10px;
  text-decoration: none;
  top: 3.8em;
  z-index: 2; }

.no-js nav a#nav-m:hover, .no-js nav a.close:hover {
  background-color: #9aff00; }

.no-js nav a#nav-m img, .no-js nav a.close img {
  height: auto;
  margin-left: 0.250em;
  width: 22px; }

.no-js nav a#nav-m:target, .no-js nav a.close {
  display: none; }

.no-js nav a:target ~ ul, .no-js nav a:target ~ .close {
  display: block; }

nav a#nav-m, nav a.close {
  display: none; }

.no-js button.menu-toggle {
  display: none; }

button.menu-toggle {
  align-items: center;
  background-color: #f6eb00;
  border-radius: 0.3em;
  border: 2px solid #00aa4f;
  color: inherit;
  display: flex;
  font-size: 1.5rem;
  margin-left: auto;
  padding: 0.3em;
  position: absolute;
  right: 10px;
  top: 3.8em; }
  button.menu-toggle:hover {
    background-color: #9aff00; }
  button.menu-toggle img {
    height: auto;
    margin-left: 0.250em;
    width: 22px; }
  button.menu-toggle[aria-expanded="true"] img.menu-img, button.menu-toggle[aria-expanded="false"] img.close {
    display: none; }
  button.menu-toggle[aria-expanded="true"] img.close {
    display: inline-block; }

nav {
  background-color: #f6eb00; }
  nav > ul {
    background-color: #f6eb00;
    display: none;
    flex-direction: column;
    margin: 0 auto;
    padding: 0; }
    nav > ul.show {
      display: flex; }

nav ul li {
  margin: 0;
  padding: 0;
  position: relative; }
  nav ul li a.menu-link {
    align-items: baseline;
    border-bottom: 1px solid gray;
    color: #000;
    display: flex;
    font-weight: 500;
    justify-content: center;
    margin: 0;
    padding: .25rem 1.5rem;
    text-decoration: underline;
    text-underline-position: under; }
  nav ul li a:hover, nav ul li a:focus {
    background-color: #006630;
    color: #fff;
    text-decoration-color: currentColor; }
  nav ul li a.active {
    background-color: #d3d3d3;
    color: currentColor;
    cursor: default;
    text-decoration: none; }
    nav ul li a.active:hover, nav ul li a.active:focus {
      background-color: #d3d3d3;
      color: currentColor;
      cursor: default;
      text-decoration: none; }
  nav ul li a.menu-link svg {
    align-self: center; }

@media screen and (min-width: 769px) {
  .no-js nav a#nav-m, .no-js nav a.close {
    display: none; }
  nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0;
    max-width: 48em; }
    nav ul li a.menu-link {
      border-bottom: none; }
  nav button.menu-toggle {
    display: none; }
  nav li a {
    border-bottom: 1px solid gray; } }

.to-top-wrap {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  right: 0.75rem;
  top: 132vh;
  width: 3em; }
  .to-top-wrap.kurz {
    top: 102vh; }

.to-top {
  background-color: #009bff4d;
  background-image: url("../img/pfeilup.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 30px;
  border-radius: 50%;
  border: 1px solid #007cff;
  display: inline-block;
  height: 3rem;
  opacity: 0.8;
  pointer-events: all;
  position: -webkit-sticky;
  position: sticky;
  top: calc(100vh - 5rem);
  transition: 0.5s;
  width: 3rem; }
  .to-top:focus, .to-top:hover {
    background-color: #009bff99;
    border: 1px solid #0047c5;
    box-shadow: 0 0 0 2px #0047c5;
    outline: none;
    transform: scale(1.05); }

@media only screen and (min-width: 1150px) {
  .to-top-wrap {
    right: 1rem; } }

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px; }
  .flex > main {
    max-width: 52em;
    min-width: 254px;
    padding: 0 calc(0.7em + 1.5vw); }

/*  Layout SASS File */
@media screen and (min-width: 48.063em) {
  .wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 74em; }
  .flex {
    flex-direction: row;
    flex-flow: wrap; }
    .flex > main.w100 {
      width: 100%; } }

footer {
  background-color: #f6eb00;
  margin-top: 1em;
  padding: 1em;
  text-align: center; }
  footer p,
  footer address {
    margin: 0.5em 0; }
  footer a:hover, footer a:active, footer a:focus {
    background-color: rgba(0, 102, 255, 0.3);
    color: #001090; }
  footer a.active {
    background-color: transparent;
    border-bottom: none;
    color: #000;
    text-decoration: none; }
    footer a.active:hover {
      cursor: default; }

#up {
  opacity: 0;
  transition: .5s;
  position: fixed;
  color: transparent; }
  #up a {
    background-image: url(../img/pfeilup.svg);
    background-color: rgba(0, 155, 255, 0.3);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 26px;
    border-radius: 50%;
    border: 1px solid #007cff;
    bottom: 20px;
    opacity: .7;
    padding: 1.4rem;
    position: fixed;
    right: 15px;
    transition: .5s;
    z-index: 100; }
    #up a:focus, #up a:hover {
      background-color: rgba(0, 155, 255, 0.6);
      border: 1px solid #005cff;
      opacity: 1; }

@media screen and (min-width: 48.063em) {
  footer p,
  address {
    margin: 0.75em 0; } }
