// Header Mobile
#top {
  background-color: #0a2d6d; }

.header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  img {
    width: 13em; }

  h1 {
    color: red;
    font-weight: 700;
    text-decoration: none; } }

@media only screen and (max-width: 23.75em) {
  .header {
    img {
      height: 140px;
      margin-left: -14px; }

    h1 {
      margin-top: -0.75em; } } }
