// Layout Mobile
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px;

  > main {
    max-width: 52em;
    min-width: 254px;
    padding: 0 calc(0.7em + 1.5vw); } }
