.no-js nav a {
    &#nav-m, &.close {
        background: #f6eb00;
        border-radius: .3em;
        border: 2px solid #00AA4F;
        color: #000;
        display: block;
        font-size: 1.5rem;
        padding: .1em .25em;
        position: absolute;
        right: 10px;
        text-decoration: none;
        top: 3.8em;
        z-index: 2; }

    &#nav-m:hover, &.close:hover {
        background-color: #9aff00; }

    &#nav-m img, &.close img {
        height: auto;
        margin-left: 0.250em;
        width: 22px; }

    &#nav-m:target, &.close {
        display: none; }

    &:target ~ {
        ul, .close {
            display: block; } } }

nav {
    a {
        &#nav-m, &.close {
            display: none; } } }

.no-js button.menu-toggle {
    display: none; }

button.menu-toggle {
    align-items: center;
    background-color: #f6eb00;
    border-radius: 0.3em;
    border: 2px solid #00aa4f;
    color: inherit;
    display: flex;
    font-size: 1.5rem;
    margin-left: auto;
    padding: 0.3em;
    position: absolute;
    right: 10px;
    top: 3.8em;

    &:hover {
        background-color: #9aff00; }

    img {
        height: auto;
        margin-left: 0.250em;
        width: 22px; }

    &[aria-expanded="true"] img.menu-img, &[aria-expanded="false"] img.close {
        display: none; }
    &[aria-expanded="true"] img.close {
        display: inline-block; } }

nav {
    background-color: #f6eb00;
    > ul {
        background-color: #f6eb00;
        display: none;
        flex-direction: column;
        margin: 0 auto;
        padding: 0;

        &.show {
            display: flex; } } }

nav ul li {
    margin: 0;
    padding: 0;
    position: relative;
    a {
        &.menu-link {
            align-items: baseline;
            border-bottom: 1px solid gray;
            color: #000;
            display: flex;
            font-weight: 500;
            justify-content: center;
            margin: 0;
            padding: .25rem 1.5rem;
            text-decoration: underline;
            text-underline-position: under; }

        &:hover, &:focus {
            background-color: #006630;
            color: #fff;
            text-decoration-color: currentColor; }

        &.active {
            background-color: #d3d3d3;
            color: currentColor;
            cursor: default;
            text-decoration: none;

            &:hover, &:focus {
                background-color: #d3d3d3;
                color: currentColor;
                cursor: default;
                text-decoration: none; } }

        &.menu-link svg {
            align-self: center; } } }

@media screen and (min-width: 769px) {
    .no-js nav a {
        &#nav-m, &.close {
            display: none; } }

    nav {
        > {}
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 0;
            max-width: 48em;

            li a.menu-link {
                border-bottom: none; } }

        button.menu-toggle {
            display: none; }

        li a {
            border-bottom: 1px solid gray; } } }
