// Footer Mobile
footer {
  background-color: #f6eb00;
  margin-top: 1em;
  padding: 1em;
  text-align: center;

  p,
  address {
    margin: 0.5em 0; }

  a {
    &:hover, &:active, &:focus {
      background-color: rgba(0, 102, 255, 0.3);
      color: #001090; }

    &.active {
      background-color: transparent;
      border-bottom: none;
      color: #000;
      text-decoration: none;

      &:hover {
        cursor: default; } } } }

#up {
  opacity: 0;
  transition: .5s;
  position: fixed;
  color: transparent;

  a {
    background-image: url(../img/pfeilup.svg);
    background-color: rgba(0, 155, 255, 0.3);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 26px;
    border-radius: 50%;
    border: 1px solid #007cff;
    bottom: 20px;
    opacity: .7;
    padding: 1.4rem;
    position: fixed;
    right: 15px;
    transition: .5s;
    z-index: 100;

    &:focus, &:hover {
      background-color: rgba(0, 155, 255, 0.6);
      border: 1px solid #005cff;
      opacity: 1; } } }
