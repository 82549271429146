/*  Layout SASS File */
@media screen and (min-width: 48.063em) {
  .wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: 74em; }

  .flex {
    flex-direction: row;
    flex-flow: wrap;
    > main {
      &.w100 {
        width: 100%; } } } }
