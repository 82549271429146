.to-top-wrap {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  right: 0.75rem;
  top: 132vh;
  width: 3em;
  &.kurz {
    top: 102vh; } }

.to-top {
  background-color: #009bff4d;
  background-image: url("../img/pfeilup.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 30px;
  border-radius: 50%;
  border: 1px solid #007cff;
  display: inline-block;
  height: 3rem;
  opacity: 0.8;
  pointer-events: all;
  position: -webkit-sticky;
  position: sticky;
  top: calc(100vh - 5rem);
  transition: 0.5s;
  width: 3rem;

  &:focus, &:hover {
    background-color: #009bff99;
    border: 1px solid #0047c5;
    box-shadow: 0 0 0 2px #0047c5;
    outline: none;
    transform: scale(1.05); } }

@media only screen and (min-width: 1150px) {
  .to-top-wrap {
    right: 1rem; } }
