@media only screen and (min-width: 48.063em) {
  .header {
    img {
      width: 18em;
      margin-right: 10vw; }

    h1 {
      font-size: 4.2em; } } }

@media only screen and (min-width: 57.5em) {
  .header {
    img {
      width: 20em; }

    h1 {
      font-size: 4.5em; } } }
